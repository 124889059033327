/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/SendRounded';
import NavbarV2 from '../../../component/navbar-v2.js'
import styles from './chat.css'
import messagerie from '../../../assets/images/messagerie.svg';
import ColisBotAvatar from '../../../assets/images/colisbot.svg';
import Avatar from '@mui/material/Avatar';
import Typing from '../typing/typing.js';


function ChatPage(){
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const socket_endpoint = "wss://backend.colismi.com";
    const [user, setUser] = useState(undefined);
    const [localUserID, setlocalUserID] = useState(localStorage.getItem("id"));
    const [discussions, setDiscussions] = useState([]);
    const [selectedDiscussion, setSelectedDiscussion] = useState(undefined);
    const [otherUser, setOtherUser] = useState(undefined);
    const [messages, setMessages] = useState([]);
    const messagesRef = useRef(messages);
    const [socket, setSocket] = useState(undefined);
    const [typing, setTyping] = useState(false);
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
  
    function stringAvatar(name) {
        if (name.toLowerCase() === 'colisbot') {
            return {
                sx: {
                    width: '80%',
                    height: '80%',
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '24px',
                    textTransform: 'uppercase'
                },
                src: ColisBotAvatar, // Set the source to the custom avatar
            };
        }
        return {
          sx: {
            bgcolor: stringToColor(name),
            width: '80%',
            height: '80%',
            fontFamily: 'Arial, sans-serif', // Use a more elegant font
            fontSize: '24px', 
            textTransform:'uppercase'
            
          },
          children: `${name[0]}`,
        };
    }
    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

  function envoyerMessage(){
    const texte = document.getElementById('texte').value;
    document.getElementById('texte').value = "";
    
    sendMessage(texte);
  }

  function getMe(){
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
                 'Authorization': 'Bearer '+token
      }
    };
    
    fetch(backend_url+'/user/me', requestOptions)
    .then(response => {
        if (response.ok){
            return response.json();
        }
        
    })
    .then(data => {
        
        setlocalUserID(data.id)
        setUser(data);
    
    })
    }
    
    function chooseDiscussion(discussion){
        let id = localStorage.getItem("id");
        const items = document.querySelectorAll('.liste-user');

        items.forEach(item => {
        item.addEventListener('click', () => {
            //items.forEach(i => i.classList.remove('liste-user-active'));

            // item.classList.add('liste-user-active');
             });
        });
        if (selectedDiscussion !== discussion){
            if (socket != undefined && socket.readyState == WebSocket.OPEN ){
                socket.close();
            }
            const sock = new WebSocket(socket_endpoint+"/chat/"+discussion.numero+"/"+id);
            sock.onopen = socketOpened;
            sock.onmessage = messageReceived;
            setSocket(sock);
        }
       
        if(discussion.emetteur.id == id){
            setOtherUser(discussion.recepteur);
        }else{
            setOtherUser(discussion.emetteur);
        }

        setSelectedDiscussion(discussion);
        getMessages(discussion);

        //Open socket    
       
    }
    function updateMessageList(obj){

        setMessages([...messagesRef.current, obj]);
    }

    function sendMessage(message){
        if (message === ""){
            return
        }
        setTyping(false);
        if (selectedDiscussion === undefined){
            console.log("select discuss is fals");
            return
        }
        let token = localStorage.getItem("token");
        const currentDate = new Date();
        const data = {
            id_discussion: selectedDiscussion.id,
            texte: message,
            date_ajout: currentDate,
            id_user: user.id,
            type: '',
            extra_data: '',
            user_token: otherUser.pseudo === "ColisBot" ? token : ''  // Set conditionally
          }; 

        const requestOptions = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
        
        },
          body: JSON.stringify({ 
           ...data
          })};
        
         
        
        
        //var copiedArray = [...messages];
       // copiedArray.push();
        setMessages([...messages,{id_user: user.id, texte: message, date_ajout: currentDate, type: '', extra_data : ''}]);
        setTimeout(()=>{
            var objDiv = document.getElementById("liste-des-messages");
            if (objDiv !== undefined){
            objDiv.scrollTo({
                    top: objDiv.scrollHeight
            });
          } 

        },1000);
        /* Send websocket message*/
        var jsonData = JSON.stringify(data);
        if (socket != undefined && socket.readyState == WebSocket.OPEN ){
            socket.send(jsonData);
        }else{
            //reopen socket
            let id = localStorage.getItem("id");
            const sock = new WebSocket(socket_endpoint+"/chat/"+selectedDiscussion.numero+"/"+id);
            sock.onopen = socketOpened;
            sock.onmessage = messageReceived;
            setSocket(sock);
            socket.send(jsonData);
        }
        // Check if the other user is not ColisBot before making the messages (to save to DB)API call
        // console.log("Ben test chat otherUser = ", otherUser);
        if (otherUser.pseudo !== "ColisBot") {
            fetch(backend_url+'/messages', requestOptions)
            .then(response => {
                setTyping(false);
                return response.json();
            })
        }

    }

    function isSameDay(dateStr1, dateStr2) {
        const date1 = new Date(dateStr1);
        const date2 = new Date(dateStr2);
        let val = date1.getFullYear() === date2.getFullYear() &&
               date1.getMonth() === date2.getMonth() &&
               date1.getDate() === date2.getDate();

        if (val !== undefined){
            return val;
        }
      }

    function getMyDiscussions(){
        let token = localStorage.getItem("token");
        const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/discussions/get/all', requestOptions)
        .then(response => {
            if (response.ok){
                return response.json();
            }
            
        })
        .then(data => {
            setDiscussions(data);
            if(data != undefined && data.length >0 ){
                chooseDiscussion(data[0]);
                getMessages(data[0]);
                /*getMessages(data[0]);
                setSelectedDiscussion(data[0]);
                setSocket(new WebSocket(socket_endpoint+"/chat/"+data[0].numero+"/"+id));
                setTimeout(()=>{
                    if (socket !== undefined){
                        socket.onopen = socketOpened;
                        socket.onmessage =  messageReceived;
                    }
                    
                },5000);*/
            }
            
        })
    }
    function getMessages(selected){
        let token = localStorage.getItem("token");
        const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
        }
        };
        
        fetch(backend_url+'/discussions/messages/'+selected.id, requestOptions)
        .then(response => {
            if (response.ok){
                return response.json();
            }
            
        })
        .then(data => {
            
            setMessages([...data]);
           
            setTimeout(()=>{
                var objDiv = document.getElementById("liste-des-messages");
                if (objDiv !== undefined){
                    objDiv.scrollTo({
                            top: objDiv.scrollHeight
                    });
                  } 
            },1000);
            
            
        })
    }

    function handleEnterPressed(e){
       
        if (e.key === 'Enter') {
                envoyerMessage();
        }
          
    }

    function formatDate(dateString){
        // Handle different types of dateString
        let date;
        if (typeof dateString === 'string') {
            // If dateString is a string, ensure it ends with 'Z' for UTC
            const utcDateStr = dateString.endsWith('Z') ? dateString : `${dateString}Z`;
            date = new Date(utcDateStr); // Convert the string to a Date object
        } else if (dateString instanceof Date) {
            // If dateString is already a Date object, use it directly
            date = new Date(dateString);
        }        

        if (isNaN(date.getTime())) {
            console.error("Invalid Date:", dateString);
            return "Invalid Date";
        }
        const hours = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return hours
    }
    function socketOpened(event) {
        console.log("WebSocket connection opened:", event);
        // You can send data to the server now
    }

    function messageReceived(event) {
        const obj = JSON.parse(event.data);
        // You can send data to the server now
        //var copiedArray = [...messages];
        //copiedArray.push(JSON.parse(event.data.replace(/'/g, '"')));
        //copiedArray.push(obj);
        if (messagesRef.current.length >0 && messagesRef.current[messagesRef.current.length-1].type === "typing"){
            const updatedMessages = messagesRef.current.slice(0, messagesRef.current.length - 1);
            setMessages(updatedMessages);
            setTimeout(()=>{
                updateMessageList(obj);
                console.log("update message list");
                console.log(obj);
            }, 1000)
        }else{
            console.log("update message list");
            updateMessageList(obj);
            console.log(obj);
        }
        
        if (obj.type === "typing"){
            console.log("received typing message");
            setTimeout(()  =>{
                console.log("remove typing")
                if(messagesRef.current.length >0){
                   
                    if (messagesRef.current[messagesRef.current.length-1].type === "typing"){
                        const updatedMessages = messagesRef.current.slice(0, messagesRef.current.length - 1);
                        setMessages(updatedMessages);
                    }
                }
            }, 120000);
        }
        //getMessages(selectedDiscussion);
        var objDiv = document.getElementById("liste-des-messages");
        if (objDiv !== undefined){
            objDiv.scrollTo({
                    top: objDiv.scrollHeight
            });
          } 
    }
    useEffect(()=>{
            document.title = "Messagerie";
            getMe();
            getMyDiscussions();
            console.log(size);
            setSize({});
    },[]);

    useEffect(() => {
        messagesRef.current = messages;
        var objDiv = document.getElementById("liste-des-messages");
            if (objDiv !== undefined){
            objDiv.scrollTo({
                    top: objDiv.scrollHeight
            });
          } 
      }, [messages]); // Runs whenever `items` is updated

      useEffect(() => {
        if (socket !== undefined){
            socket.onopen = socketOpened;
            socket.onmessage = messageReceived;
        }
      }, [socket]); // Runs whenever `items` is updated

  function convertDateForHuman(dtstr){
    const date = new Date(dtstr);
        return date.toLocaleDateString(); 
  }

  function userIsWriting(event){

   
    if(typing === false){
        setTyping(true);
        let token = localStorage.getItem("token");

        const currentDate = new Date();
        const data = {
            id_discussion: selectedDiscussion.id,
            texte: "",
            date_ajout: currentDate,
            id_user: user.id,
            type: 'typing',
            extra_data: '',
            user_token: otherUser.pseudo === "ColisBot" ? token : ''  // Set conditionally
        }; 

        var jsonData = JSON.stringify(data);
        if (socket != undefined && socket.readyState == WebSocket.OPEN ){

            socket.send(jsonData);
        }else{
            let id = localStorage.getItem("id");
            const sock = new WebSocket(socket_endpoint+"/chat/"+selectedDiscussion.numero+"/"+id);
            sock.onopen = socketOpened;
            sock.onmessage = messageReceived;
            setSocket(sock);
            socket.send(jsonData);
        }

    }

    if (event.target.value === ""){
        setTyping(false);
    }

  }
  function renderMessageText(text) {
    return text.split('\\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
  }
  // Function to send the user's response back to the server
  function sendConfirmReconfigResponse(response, type) {
    const confirmationMessage = {
        type: type,
        response: response,
    };
    socket.send(JSON.stringify(confirmationMessage));
  }
  return (
      <div className={styles}>
            <NavbarV2/>
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-6.jpg')]" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

               

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h6 className="font-medium leading-normal text-3xl mt-10 text-white">Messagerie</h6>
                        <h1 className="font-medium leading-normal text-sm mt-1 text-white">Communiquez avec les colisnautes.</h1>
                    </div>
                </div>
            </section>
            <section className='chat-zone'>
            <Grid container>
            <Grid item xs={12} >
                <Typography variant="h5" className="header-message"></Typography>
            </Grid>
            </Grid>
            <Grid >
                
                
            </Grid>
            <div className="chat-container">
                <div className="chat-users-container">
                <Grid item  sx={{borderRight: '1px solid #e0e0e0'}}>
                    <List className='hidden'>
                        {user &&
                        <ListItem key="RemySharp">
                            <ListItemIcon>
                                <Avatar id='menu-avatar' {...stringAvatar(user.pseudo)}/>
                            </ListItemIcon>
                            <ListItemText primary={user.pseudo}></ListItemText>
                        </ListItem>
                        }
                    </List>
                    <Divider />
                    <List id='liste-messages'>{
                        discussions.map((discussion, index)=>{
                            if(discussion.recepteur.id === localUserID ){
                                return(
                                    <div key={index} className='liste-user-div'>
                                <ListItem className='liste-user'  onClick={() => chooseDiscussion(discussion)}>
                                    <ListItemIcon>
                                        <Avatar id='menu-avatar' {...stringAvatar(discussion.emetteur.pseudo)}/>
                                    </ListItemIcon>
                                    <ListItemText primary={discussion.emetteur.pseudo}>{discussion.emetteur.pseudo}</ListItemText>
                                    { discussion.emetteur.online === true &&
                                        <ListItemText secondary="en ligne" align="right"  className='enligne' sx={{color:'green'}}></ListItemText>
                                    }
                                </ListItem>
                                {discussion.messages.length >0 &&
                                 <div className='last-message-div'>
                                    <p className='last-message'>{discussion.messages[discussion.messages.length-1].texte}</p>
                                    { discussion.messages[discussion.messages.length-1].id_user !== localUserID &&
                                        <div id="circle"></div>
                                    }
                                </div>
                                }
                                <Divider />
                                </div>)
                            }
                            
                            if(discussion.emetteur.id === localUserID){
                                return(
                                <div key={index} className='liste-user-div'>
                                <ListItem  className='liste-user' onClick={() => chooseDiscussion(discussion)}>
                                    <ListItemIcon >
                                         <Avatar id='menu-avatar' {...stringAvatar(discussion.recepteur.pseudo)}/>
                                    </ListItemIcon>
                                    <ListItemText primary={discussion.recepteur.pseudo}>{discussion.recepteur.pseudo}</ListItemText>
                                    { discussion.recepteur.online === true &&
                                        <ListItemText secondary="en ligne" align="right"  className='enligne' sx={{color:'green'}}></ListItemText>
                                    }
                                </ListItem>
                                {discussion.messages.length >0 &&
                                    <div className='last-message-div'>
                                      <p className='last-message'>{discussion.messages[discussion.messages.length-1].texte}</p>
                                      { discussion.messages[discussion.messages.length-1].id_user !== localUserID &&
                                        <div id="circle"></div>
                                      }
                                   </div>
                                }
                                <Divider />
                                </div>
                                )
                            }
                        })
                       }  
                       {discussions && discussions.length == 0 &&
                            <p style={{textAlign: 'center'}}>Vous n'avez pas de discussion en cours</p>
                       } 
                       
                    </List>
                </Grid>
                </div>
                <div className="chat-msg-container">
                <Grid item xs={12}   >
                    { otherUser &&
                    <div id="header-messages">                       
                        <span> <Avatar id='menu-avatar' {...stringAvatar(otherUser.pseudo)}/> </span>
                        <span> <h1>{otherUser.pseudo}</h1> </span>
                    </div>
                       }

                    <List sx={{height:'70vh', overflowY: "auto"}} id="liste-des-messages">
                        {
                            messages.map((message, index, array)=>{
                                <div></div>
                                if (message.type === "typing"){
                                    return(
                                    <div  key={index}>
                                        <Typing />
                                    </div>
                                    )
                                }
                                if(message.type === "" && message.id_user == localUserID){
                                    return(
                                    <div key={index}>
                                    {index >0 && !isSameDay(array[index].date_ajout, array[index-1].date_ajout) &&
                                        <div style={{height: '40px', textAlign:'center', fontSize: '14px'}}><p>{convertDateForHuman(array[index].date_ajout)}</p></div>
                                    }
                                    <ListItem  className='message-item'>
                                        <Grid container >
                                            <Grid item xs={12} >
                                                <ListItemText align="right" primary={renderMessageText(message.texte)} className='message-item-text-right'></ListItemText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ListItemText align="right" secondary={formatDate(message.date_ajout)}></ListItemText>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    </div>
                                    )
                                }if(message.type === "confirmation"){
                                    return(
                                        <div className="message-container" key={index}>
                                            <p className="message-text">{message.texte}</p>
                                            <button className="button button-yes" key='oui' onClick={() => sendConfirmReconfigResponse('Oui', 'confirmation')}>Oui</button>
                                            <button className="button button-no" key='non' onClick={() => sendConfirmReconfigResponse('Non', 'confirmation')}>Non</button>
                                        </div> 
                                    )
                                }if(message.type === "reconfigure"){
                                    return(
                                        <div className="message-container" key={index}>
                                            <p className="message-text">{message.texte}</p>
                                            {message.options.map((option, idx) => (
                                                <button className="button button-reconfigure" key={idx} onClick={() => sendConfirmReconfigResponse(option, 'reconfigure')}>{option}</button>
                                            ))}
                                        </div>                                        
                                    )
                                }if(message.type === "" && message.id_user !== localUserID){
                                    return(
                                        <div key={index}>
                                        {index >0 && !isSameDay(array[index].date_ajout, array[index-1].date_ajout) &&
                                        <div style={{height: '40px', textAlign:'center', fontSize: '14px'}}><p>{convertDateForHuman(array[index].date_ajout)}</p></div>
                                        }
                                        <ListItem >
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <ListItemText align="left" primary={renderMessageText(message.texte)} className='message-item-text-left'></ListItemText>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ListItemText align="left" secondary={formatDate(message.date_ajout)}></ListItemText>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        </div>)
                                }
                            })
                        }
                        { messages.length == 0 &&
                            <div>
                                <h1 style={{textAlign: 'center', color:'#5F2972'}}>Aucun message à afficher</h1>
                                <br/>
                                 <img src={messagerie} className='image-no-res'/>
                            </div>  
                       
                       }
                    </List>
                    <Divider />
                    <Grid container style={{padding: '20px'}}>
                        <Grid item xs={11}>
                            <TextField id="texte" label="Ecrivez votre message ici" className='input-wrapper' onKeyUp={handleEnterPressed} fullWidth  onChange={userIsWriting}/>
                        </Grid>
                        <Grid xs={1} align="right">
                            <Fab aria-label="add" className='button-send-message' sx={{backgroundColor:'#5f2972', color:'#ffff', zIndex:'90'}} onClick={envoyerMessage}><SendIcon /></Fab>
                        </Grid>
                    </Grid>
                </Grid>
                </div>
            </div>
        </section>
      </div>
  );
}

export default ChatPage;