/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import Navbar from '../../../component/navbar'
import Footer from '../../../component/footer';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import ajout_annonce from '../../../assets/images/colismi/trajet/ajout_annonce.svg';

export default function AddTravel() {
    const backend_url= process.env.REACT_APP_BACKEND_URL;
    const [villes, setVilles] = useState([]);
    const [deactivateButton, setDeactivateButton] = useState(true);
    const [checkPrix, setCheckPrix] = useState(false);
    const [checkCapacite, setCheckCapacite] = useState(false);
    const [type_transport, setTypeTransport] = useState(undefined);
    const [isLoggedin, setIsLoggedin] = useState(false);
    const options = [
        { value: 'Avion', label: 'Avion' },
        { value: 'Bateau', label: 'Bateau' },
        { value: 'Fret Aerien', label: 'Fret Aerien' }
      ]
    
      function selectHasChanged(item){
        setTypeTransport(item.value);
      }

    useEffect(() => {

        async function getVilles() {
            try {
              const response = await fetch(backend_url+'/villes');
              const data = await response.json();
              setVilles(data);
            } catch (error) {
              console.error(error);
            }
        }

        getVilles();
        let token = localStorage.getItem("token");
        if(token == undefined){
          setIsLoggedin(false)
        }else{
          setIsLoggedin(true)
        }
    
        
      }, []);

      function handleFormChange(){
        setDeactivateButton(true);
        let array =   [];
        array.push(document.getElementById('input-depart').value);
        array.push(document.getElementById('input-arrivee').value);

        //array.push(document.getElementById('kilos').value);
        if(!isLoggedin){
            array.push(document.getElementById('email').value);
            array.push(document.getElementById('pseudo').value);
        }
        
        array.push(document.getElementById('date-depart').value);
        array.push(document.getElementById('date-arrivee').value);
        
        //array.push(document.getElementById('cout').value);

        for (const element of array) {
            if(element == undefined ){
                
                return
            }
            if(element == "" ){
                return
            }
        }
        setDeactivateButton(false);
      }

      function addTrajet(){
        let email ="";
        let telephone = "";
        let pseudo = "";
        let endpoint = "/trajets/add/public";
        let headers = {};

        if(!isLoggedin){
            telephone = document.getElementById('telephone').value;
            email = document.getElementById('email').value;
            pseudo = document.getElementById('pseudo').value;
            headers = { 
                'Content-Type': 'application/json'
            }
        }else{
            endpoint = "/trajets/add";
            let token = localStorage.getItem("token");
            headers = { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            }
        }

     
        const depart = document.getElementById('input-depart').value;
        const arrivee = document.getElementById('input-arrivee').value;
        
        const description = document.getElementById('description').value;
        const date_depart = document.getElementById('date-depart').value;
        const date_arrivee = document.getElementById('date-arrivee').value;

        const kilos = document.getElementById('kilos') ?document.getElementById('kilos').value: -1;
        let cout = document.getElementById('cout')? document.getElementById('cout').value: -1;

        //let prx_checkbox = document.getElementById('prix-checkbox');
        //let capa_checkbox = document.getElementById('capacite-checkbox');
        
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ 
            ville_depart: depart,
            ville_arrivee: arrivee,
            date_depart: date_depart,
            date_arrivee: date_arrivee,
            type: type_transport,
            capacite: kilos,
            telephone: telephone,
            description: description,
            email: email,
            prix: cout,
            pseudo: pseudo
          
          })};
        
        fetch(backend_url+endpoint, requestOptions)
        .then(response => {
            
            return response.json();
        })
        .then(data => {
            if(data.detail != undefined){
                toast.error(data.detail);
            }else{
                toast.success("Annonce ajoutée avec succès!");
            }
            
            document.getElementById('input-depart').value = "";
                document.getElementById('input-arrivee').value = "";
                //document.getElementById('kilos').value = "";
                document.getElementById('description').value = "";
                document.getElementById('date-depart').value = "";
                document.getElementById('date-arrivee').value = "";
                //document.getElementById('cout').value = "";
                handleFormChange();
        })
        .catch(error => {
            
            console.log("error")
            toast.error("Impossible d'ajouter l'annonce!, veuillez réessayer plus tard");
            console.log(error);
        });
      }

    function handleChangePrix(event){
        setCheckPrix(event.target.checked);
    }

    function handleChangeCapacite(event){
        setCheckCapacite(event.target.checked);
    }

    return (
        <>
            <Navbar />
            <ToastContainer />
            <section className="py-12 w-full table relative bg-[url('../../assets/images/bg/bg-6.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>

                <div className="container">
                    <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h1 className="leading-normal text-2xl text-white">Vous voyagez et avez de l'espace disponible?</h1>
                        <h6 className="leading-normal text-sm text-white">Proposez un trajet pour les utilisateurs de Colismi</h6>

                    </div>
                </div>
                </div>
            </section>
            <section className="relative">
                <div className='container'>
                    <p className='description-about'>Vous  voyagez et vous souhaitez rentabiliser votre voyage en mettant en vente
                        une partie de votre espace disponible.
                        Vous êtes professionnel et effectuez régulièrement l'envoi de marchandises, à l'international?
                        Postez votre annonce ici.
                    </p>
                </div>
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={ajout_annonce} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">Ajouter une annonce !</h3>

                                    <form name="myForm" id="myForm" >
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid grid-cols-1">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="form-label font-medium">Ville depart:</label>

                                                <Autocomplete
                                                    className='autocomplete'
                                                    disablePortal
                                                    fullWidth
                                                    id="input-depart"
                                                    options={villes}
                                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                                    renderInput={(params) => <TextField {...params}  variant="outlined"   style={{ backgroundColor: '#f6f1f8' }} />}
                                                    />

                                                
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="form-label font-medium">Ville arrivee:</label>
                                                
                                                <Autocomplete
                                                    className='autocomplete'
                                                    disablePortal
                                                    id="input-arrivee"
                                                    fullWidth
                                                    options={villes}
                                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                                    renderInput={(params) => <TextField {...params}  variant="outlined"   style={{ backgroundColor: '#f6f1f8' }} />}
                                                    />
                                            </div>
                                        </div>

                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="date-depart" className="form-label font-medium">Date depart:</label>
                                                <input onChange={handleFormChange} required name="date-depart" id="date-depart" type="date" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>
                                            
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="date-arrivee" className="form-label font-medium">Date arrivee:</label>
                                                <input  onChange={handleFormChange} required name="date-arrivee" id="date-arrivee" type="date" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>
                                            {!isLoggedin && 
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="form-label font-medium">Email:</label>
                                                <input onChange={handleFormChange} required name="email" id="email" type="email" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>
                                            }
                                            {!isLoggedin &&
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="telephone" className="form-label font-medium">Telephone:</label>
                                                <input onChange={handleFormChange} required name="telephone" id="telephone" type="number" pattern="[0-9]*" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>
                                            }
                                        </div>

                                        <div className="grid grid-cols-1">
                                            {!isLoggedin &&
                                            <div className="mb-5">
                                                <label htmlFor="pseudo" className="form-label font-medium">Pseudo:</label>
                                                <input onChange={handleFormChange} required name="pseudo" id="pseudo" className="input-wrapper w-full py-2 px-3 h-10  border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>
                                            }
                                            <div className="mb-5">
                                            <FormGroup>
                                                        <FormControlLabel control={<Checkbox id='capacite-checkbox' checked={checkCapacite}  onChange={handleChangeCapacite}/>} label="Definir la capacité" />
                                                 </FormGroup>
                                                 { checkCapacite &&
                                                <div>
                                                <label htmlFor="kilos" className="form-label font-medium">Capacite de transport(Kg):</label>
                                                <input onChange={handleFormChange} 
                                                            required name="kilos" 
                                                            type='number'
                                                            pattern="[0-9]*" 
                                                            id="kilos" className="input-wrapper w-full py-2 px-3 h-10  border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                
                                                </div>
                                                }
                                             </div>
                                            <div className="mb-5">
                                                <FormGroup>
                                                        <FormControlLabel control={<Checkbox id='prix-checkbox' checked={checkPrix}  onChange={handleChangePrix}/>} label="Définir le prix par kilogramme" />
                                                 </FormGroup>
                                                 { checkPrix &&
                                                 <div>
                                                    <label htmlFor="cout" className="form-label font-medium">Cout du kilo(€):</label>
                                                    <input onChange={handleFormChange} 
                                                        required name="cout" 
                                                        type='number' 
                                                        pattern="[0-9]*" 
                                                        id="cout" 
                                                        className="input-wrapper w-full py-2 px-3 h-10  border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                                </div>
                                                }
                                            </div>
                                            <div className="mb-5">
                                            <Select 
                                                label="Type de transport"
                                                placeholder="Type de transport"
                                                inputId='type-transport'
                                                name='type-transport'
                                                onChange={selectHasChanged}
                                                styles={{
                                                    control: (baseStyles) => ({
                                                      ...baseStyles,
                                                      backgroundColor: '#f6f1f8'
                                                    }),
                                                }}
                                                options={options} />
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="description" className="form-label font-medium">Description:</label>
                                                <textarea onChange={handleFormChange} required name="description" id="description" className="input-wrapper w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28" placeholder=""></textarea>
                                            </div>
                                        </div>
                                        
                                    </form>
                                    <button disabled={deactivateButton} onClick={addTrajet} id="submit" name="send" className="colismi-button w-full">Ajouter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}